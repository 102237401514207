<template>
  <div class="login">
    <div class="container-fluid px-1 px-md-5 px-lg-1 px-xl-5 py-5 mx-auto">
      <div class="card card0 border-3" style="border-radius:10px;">
        <div class="row d-flex">
          <div class="col-lg-7">
            <div class="card1 pb-5">
              <div class="row">
                <img src="/logo.png" class="logo" />
              </div>
              <div class="row px-3 justify-content-center mt-4 mb-5">
                <img src="/nuta.png" class="image" style="padding-top:100px" />
              </div>
            </div>
          </div>
          <div class="col-lg-5 border-line">
            <form @submit.prevent="perusahaanLogin">
              <div class="card2 card border-0 px-4 py-5">
                <div class="row">
                  <div class="col-lg-12 text-center">
                      <h4><b>LOGIN</b></h4>
                  </div>
                </div>
                <div class="row py-13" style="padding-top:10%;padding-left:5%">
                  <div class="col-0" style="
                  width:25px;
                  height:25px;
                  background-color:#00ae2b;
                  color:white;
                  border-radius:100%;
                  padding-left:8px;
                  padding-top:1px;
                  ">1</div>
                  <div class="col-4" style="margin:10px 3% 10px 3%;border-top: 3px solid #9b9b9b;"></div>
                  <div class="col-0" style="
                  width:25px;
                  height:25px;
                  background-color:#9b9b9b;
                  color:white;
                  border-radius:100%;
                  padding-left:8px;
                  padding-top:1px;
                  ">2</div>
                  <div class="col-4" style="margin:10px 3% 10px 3%;border-top: 3px solid #9b9b9b;"></div>
                  <div class="col-0" style="
                  width:25px;
                  height:25px;
                  background-color:#9b9b9b;
                  color:white;
                  border-radius:100%;
                  padding-left:8px;
                  padding-top:1px;
                  ">3</div>
                </div>

                <div class="row" style="padding-top:10%">
                  <label class="mb-1 col-lg-12 text-center">
                    Silahkan isikan berikut untuk login ke kasir Nutacloud
                  </label>
                  <div class="col-lg-12">
                    <div class="form-group">
                      <label class="floating-custom">Nama Perusahaan</label>
                      <input class=""
                      type="text" 
                      v-model="form.perusahaan"
                      name="perusahaan">
                      <i v-if="errors"><small class="text-danger">{{ errors }}</small></i>
                    </div>
                  </div>
                  <label class="mb-1 col-lg-12 text-left">
                    Belum Punya Akun NutaCloud ? <a href="https://www.nutacloud.com/account/register" style="color:#00ae2b">Daftar Disini</a>
                  </label>
                </div>
                <div class="row mb-3" style="padding-top:25%;padding-left:65%">
                  <button type="submit" class="btn btn-green text-center" style="border-radius:5px" :disabled="submitted">
                    <span v-html="btnLogin"></span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters,mapActions } from "vuex";


export default {
  data() {
    return {
      form: {
        perusahaan: "",
      },
      submitted: false,
      errors: null,
      btnLogin:"Lanjut"
    };
  },
  mounted() {
    this.getPerusahaan();
  },
  methods: {
    ...mapActions(["loginPerusahaan"]),
    getPerusahaan(){
      if (this.perusahaan !=null){
          this.form.perusahaan = this.perusahaan 
      }
    },
    perusahaanLogin() {
      this.submitted = true;
      this.btnLogin = "<div class='fa fa-circle-notch fa-spin'></div>";
      this.$store
        .dispatch("loginPerusahaan", this.form)
        .then((response) => {
          if (response.status == "OK") {
            this.submitted = false;
            this.$router.push({name: 'Login'})
          } else {
            if (this.form.perusahaan==""){
              this.errors = "Nama perusahaan tidak boleh kosong";
              this.submitted = false;
            }
            else{
              this.errors = response.message[0];
              this.submitted = false;
            }
          }
          this.btnLogin = "Lanjut";
        })
        .catch((error) => {
          this.errors = error.statusText;
          this.submitted = false;
          this.btnLogin = "Lanjut"
        });
    },
  },
  computed: {
    ...mapGetters(["perusahaan"]),
  }
};
</script>
<style scoped>
body {
  color: #000;
  overflow-x: hidden;
  height: 100%;
  background-repeat: no-repeat;
}

.card0 {
  box-shadow: 0px 3px 8px 0px #757575;
  border-radius: 0px;
}

.card2 {
  margin: 0px 40px;
}

.logo {
  width: 150px;
  height:30px;
  transform: translateX(30px) translateY(21px);
}

.image {
  width: 650px;
  height: auto;
}

.border-line {
  border-left: 1px solid #eeeeee;
  margin-top:10px;
  margin-bottom:10px;
}

.or {
  width: 10%;
  font-weight: bold;
}

input,
textarea {
  padding: 10px 12px 10px 12px;
  border: 1px solid rgb(148, 148, 148);
  border-radius: 2px;
  margin-bottom: 5px;
  margin-top: 2px;
  width: 100%;
  box-sizing: border-box;
  color: #000000;
  font-size: 14px;
  letter-spacing: 1px;
}

input:focus,
textarea:focus {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: 1px solid #00ae2b;
  outline-width: 0;
}

button:focus {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  outline-width: 0;
}

a {
  color: inherit;
  cursor: pointer;
}

.btn-green {
  background-color: #00ae2b;
  width: 150px;
  color: #fff;
  border-radius: 2px;
}

.btn-green:hover {
  background-color: #019b28;
  cursor: pointer;
}
.floating-custom {
    font-size: 14px;
    transform: translateY(95%) translateX(10%);
    background:#fff;
}
@media screen and (max-width: 991px) {
  .logo {
    margin-left: 0px;
  }

  .image {
    width: 300px;
    height: 220px;
  }

  .border-line {
    border-right: none;
  }

  .card2 {
    border-top: 1px solid #eeeeee !important;
    margin: 0px 15px;
  }
}
</style>